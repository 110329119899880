import { NextWebVitalsMetric } from 'next/app';
import getConfig from 'next/config';
import { slugIt } from '../../utils/links/common';

declare global {
  interface Window {
    gtag: (...args: any[]) => void,
  }
}

export interface TrackerArgs {
  pathname: string,
  title?: string,
}

export interface TrackingEvent {
  category: string,
  action: string,
  label: string,
  value?: number,
  dimensions?: {
    [key: string]: string,
  },
}

export interface PurchaseEvent {
  id: string,
  value: number,
}

export interface FbLeadEvent {
  value: number,
  category: string,
  name: string,
}

export const trackPageView = ({ pathname, title }: TrackerArgs, dimensions = {}) => {
  const { GTM } = getConfig().publicRuntimeConfig;
  const { ga } = window;

  if (!ga) {
    return;
  }

  ga(`${GTM.trackerName}.set`, {
    page: pathname,
    title: title || window.document.title,
  });

  ga(`${GTM.trackerName}.send`, 'pageview', dimensions);
};

export function sendEvent<T extends TrackingEvent>(event: string, params: T) {
  const { GTM } = getConfig().publicRuntimeConfig;
  const { ga, gtag } = window;

  const {
    category,
    action,
    label,
    value,
    dimensions,
    ...rest
  } = params;

  if (ga) {
    ga(`${GTM.trackerName}.send`, 'event', category, action, label, value, dimensions);
  }

  if (gtag) {
    gtag('event', event, {
      label,
      value,
      ...rest,
    });
  }
}

export function sendMicrosoftEvent<T extends TrackingEvent>(event: string, params: T) {
  const { uetq } = window;
  if (uetq) {
    const {
      category,
      label,
      value,
    } = params;
    uetq.push('event', 'submit', { event_category: category, event_label: label, event_value: value });
  }
}

export function sentPinterestLeadTracking(label: string) {
  const { pintrk } = window;
  if (pintrk) {
    pintrk('track', 'lead', { lead_type: label });
  }
}

export function sendFbPurchaseEvent(purchaseEvent: PurchaseEvent) {
  const { fbq } = window;
  if (fbq) {
    fbq('track', 'Purchase', {
      content_type: 'product',
      contents: [
        {
          id: slugIt(purchaseEvent.id), // id can't contain any space
          quantity: 1,
        },
      ],
      value: purchaseEvent.value,
      currency: 'EUR',
    });
  }
}

export function sendFbLeadEvent(leadEvent: FbLeadEvent) {
  const { fbq } = window;
  if (fbq) {
    fbq('track', 'Lead', {
      content_category: leadEvent.category,
      content_name: leadEvent.name,
      value: leadEvent.value,
      currency: 'EUR',
    });
  }
}

export function trackCoreVitals(metric: NextWebVitalsMetric) {
  const { gtag } = window;
  const {
    id,
    name,
    label,
    startTime,
    value,
  } = metric;

  if (gtag) {
    gtag('event', name, {
      label,
      id,
      value,
      startTime,
    });
  }
}
