import React, { ReactNode, createContext } from 'react';
import Router from 'next/router';
import { URLObject } from '../../utils/links';

export interface AppContextValue {
  previousURLObject: URLObject,
}

const AppContext = createContext<AppContextValue>({
  previousURLObject: null,
});

export class AppContextProvider extends React.Component<{ children: ReactNode }, AppContextValue> {
  state = {
    // eslint-disable-next-line react/no-unused-state
    previousURLObject: null,
  };

  componentDidMount() {
    Router.events.on('beforeHistoryChange', this.savePreviousURLObject.bind(this));
  }

  componentWillUnmount() {
    Router.events.off('beforeHistoryChange', this.savePreviousURLObject.bind(this));
  }

  savePreviousURLObject() {
    this.setState({
      // eslint-disable-next-line react/no-unused-state
      previousURLObject: {
        url: {
          pathname: Router.pathname,
          query: Router.query,
        },
        as: Router.asPath,
      },
    });
  }

  render() {
    const {
      children,
    } = this.props;

    return (
      <AppContext.Provider value={this.state}>
        {children}
      </AppContext.Provider>
    );
  }
}

export const AppContextConsumer = AppContext.Consumer;
