import { ArticleType } from '../domain/articleType';
import { EmploymentType } from '../domain/graphcms';

export const ArticlesBreadcrumb: { [key in ArticleType]: string } = {
  [ArticleType.GUIDE]: 'Guide',
  [ArticleType.EDUCATION]: 'Formazione',
  [ArticleType.NEWS]: 'News',
};

export const employmentTypeLabel: { [key in EmploymentType]: string } = {
  [EmploymentType.FULL_TIME]: 'Tempo pieno',
  [EmploymentType.PART_TIME]: 'Part time',
};

export const DataTrackingContext = {
  HERO: 'hero',
  MAIN: 'main',
  SIDE: 'side',
  SIDE_STICKY: 'side:sticky',
  BOTTOM_STICKY: 'bottom:sticky',
};

export const ARTICLE_PREVIEW_WIDTH = 1200;
export const ARTICLE_PREVIEW_HEIGHT = 960;

export const SITE_BASE_URL = 'https://www.dove.it';
export const COUNTRY = 'IT';
